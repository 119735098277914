:<template>
  <v-container
    :style="
      `${
        $route.path == '/gestordepedidos'
          ? ''
          : `background: ${$theme.background}`
      }; flex-direction: column;`
    "
    fluid
    class="molde pa-0 ma-0 "
  >
    <v-layout
      :style="
        `${
          $route.path == '/gestordepedidos'
            ? ''
            : `background: ${$theme.background}`
        }`
      "
      column
    >
      <div class="expande-horizontal centraliza">
        <v-flex v-if="isAndroid && !toolbar_off" xs12>
          <div class="expande-horizontal">
            <v-app-bar
              :app="$vuetify.breakpoint.smAndDown"
              dark
              height="45"
              color="#fff"
              :fixed="$vuetify.breakpoint.smAndDown"
              class="elevation-0"
              :style="
                $vuetify.breakpoint.smAndDown
                  ? 'margin-bottom: 0px; border-radius: 0px'
                  : 'border-radius: 0px;margin-bottom: 0px'
              "
            >
              <v-btn dark @click="drawer = !drawer" icon>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="#673AB7" xmlns="http://www.w3.org/2000/svg" class="icon-lg mx-2 text-token-text-secondary"><path fill-rule="evenodd" clip-rule="evenodd" d="M3 8C3 7.44772 3.44772 7 4 7H20C20.5523 7 21 7.44772 21 8C21 8.55228 20.5523 9 20 9H4C3.44772 9 3 8.55228 3 8ZM3 16C3 15.4477 3.44772 15 4 15H14C14.5523 15 15 15.4477 15 16C15 16.5523 14.5523 17 14 17H4C3.44772 17 3 16.5523 3 16Z" fill="#673AB7"></path></svg>
              </v-btn>

              <span
                @click="drawer = !drawer"
                :style="
                  `font-size: 16pt; cursor: pointer; color: ${
                    $route.path == '/gestordepedidos' ? 'white' : $theme.primary
                  }`
                "
                class="not-selectable text-no-wrap font-weight-bold fonte-bold fonteMini"
                >{{ title }}</span
              >
              <v-btn
                dark
                rounded
                color="green accent-3"
                class="fonte ml-2 elevation-0"
                @click="$refs.onboarding.open('dashboard')"
                v-if="false"
                small
              >
                <span class="fonteMini">guia</span>
                <v-icon class="ml-1" size="16">mdi-help-circle-outline</v-icon>
              </v-btn>

              <v-spacer></v-spacer>

              <div id="notifications-container">
                <v-menu v-if="false" offset-y left bottom :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      rounded
                      class="animate__animated animate__swing  elevation-0"
                      :color="
                        notificationsUnreadeds.length ? $theme.primary : 'grey'
                      "
                      v-on="on"
                    >
                      <span class="font-weight-bold">
                        {{ notificationsUnreadeds.length }}
                      </span>
                      <v-icon size="21" color="#f2f2f2"
                        >mdi-bell-outline</v-icon
                      >
                    </v-btn>
                  </template>
                  <v-card class="pa-3">
                    <v-list-item class="pa-0">
                      <v-avatar class="mr-3 elevation-1" color="#333">
                        <v-icon :color="$theme.primary">
                          mdi-bell-outline
                        </v-icon>
                      </v-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          Notificações
                        </v-list-item-title>
                        <span class="fonte fonteMini grey--text">
                          {{ notificationsUnreadeds.length }} Notificações
                          novas</span
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <div class="column centraliza">
                          <v-switch
                            small
                            hide-details
                            class="fonte fonteMini"
                            :color="$theme.primary"
                            v-model="onlyUnreadeds"
                          >
                          </v-switch>
                          <span
                            style="cursor: pointer;"
                            @click="onlyUnreadeds = !onlyUnreadeds"
                            class="fonteMini fonte grey--text"
                          >
                            não lidas
                          </span>
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list
                      class="fonte"
                      v-if="notifications.length > 0"
                      style="max-height: 300px; overflow: auto;"
                    >
                      <template
                        v-for="(notification, i) in onlyUnreadeds
                          ? notificationsUnreadeds
                          : notifications"
                      >
                        <v-list-item
                          class="item-list-notification"
                          :key="notification._id"
                          v-if="onlyUnreadeds ? !notification.readed : true"
                          @click="abre_modal_view_notification(notification)"
                        >
                          <v-avatar size="31" class="mr-3" color="#333">
                            <v-icon :color="$theme.primary" size="21">
                              {{
                                getTypeLinkByTypeNotification(notification).icon
                              }}
                            </v-icon>
                          </v-avatar>
                          <v-list-item-content>
                            <v-list-item-title>
                              <span
                                class=""
                                :class="{
                                  'font-weight-bold': !notification.readed
                                }"
                              >
                                {{
                                  getTypeLinkByTypeNotification(notification)
                                    .title
                                }}
                              </span>
                            </v-list-item-title>
                            <span
                              class="fonte grey--text fonteMini mt-1"
                              :class="{
                                'font-weight-bold': !notification.readed
                              }"
                            >
                              {{ notification.description }}
                            </span>
                            <div class="expande-horizontal">
                              <span
                                class="fonte fonteMini mt-1"
                                :style="`color: ${$theme.secondary}`"
                                :class="{
                                  'font-weight-bold': !notification.readed
                                }"
                                style="font-size: 6pt;"
                              >
                                {{ $moment(notification.created_at).fromNow() }}
                              </span>
                              <span
                                class="fonte fonteMini mt-1"
                                :style="`color: ${$theme.secondary}`"
                                v-if="notification.readed"
                                :class="{
                                  'font-weight-bold': !notification.readed
                                }"
                                style="font-size: 6pt;"
                              >
                                , foi lida
                                {{
                                  $moment(notification.readDate).format(
                                    "DD/MM/YY [às] HH:mm"
                                  )
                                }}
                              </span>
                              <span
                                class="fonte fonteMini mt-1"
                                :style="`color: ${$theme.secondary}`"
                                v-else
                                :class="{
                                  'font-weight-bold': !notification.readed
                                }"
                                style="font-size: 6pt;"
                              >
                                , não lida ainda.
                              </span>
                              <v-icon
                                class="ml-2"
                                color="green"
                                size="15"
                                v-if="!notification.readed"
                                >mdi-check</v-icon
                              >
                              <v-icon
                                class="ml-2"
                                color="green"
                                size="15"
                                v-if="notification.readed"
                                >mdi-check-all</v-icon
                              >
                            </div>
                          </v-list-item-content>
                          <!-- <v-list-item-action>
                            
                          </v-list-item-action> -->
                        </v-list-item>
                        <!-- <v-divider :key="`divider-${i}`"></v-divider> -->
                      </template>
                    </v-list>
                    <div
                      v-if="notifications.length === 0"
                      class="notifications-empty-container fonte expande-horizontal centraliza column"
                    >
                      <v-avatar class="mb-3" :color="$theme.primary">
                        <v-icon color="#f2f2f2">mdi-bell-outline</v-icon>
                      </v-avatar>
                      <span :style="`color: ${$theme.primary}`" class="fonte"
                        >Nada por aqui</span
                      >
                      <span class="text-center grey--text fonteMini fonte">
                        Novas notificações aparecerão aqui
                      </span>
                    </div>
                  </v-card>
                </v-menu>
              </div>

              <div id="options-container">
                <v-menu offset-y v-show="options" left bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-show="options && $vuetify.breakpoint.smAndDown"
                      icon
                      small
                      class="pa-3"
                      style="background: #f2f2f2"
                      v-on="on"
                    >
                      <v-icon
                        :color="
                          $route.path == '/gestordepedidos'
                            ? 'white'
                            : $theme.primary
                        "
                        >mdi-dots-vertical</v-icon
                      >
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-for="item in options"
                      :key="item.nome"
                      @click="() => item.action()"
                    >
                      <v-list-item-title class="fonte">{{
                        item.nome
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-app-bar>
          </div>
        </v-flex>
        <v-flex v-if="!isAndroid && !toolbar_off" xs12 md10>
          <div class="expande-horizontal">
            <v-app-bar
              dense
              app
              dark
              height="39"
              :color="$theme.app_bar"
              :fixed="$vuetify.breakpoint.smAndDown"
              class="elevation-0"
              :class="{
                'vitrify-content': true
              }"
              :style="
                `${
                  $route.path !== '/gestordepedidos'
                    ? 'border-bottom: 1px solid #e5e5e5;'
                    : ''
                }`
              "
            >
              <v-btn dark @click="drawer = !drawer" icon>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="#673AB7" xmlns="http://www.w3.org/2000/svg" class="icon-lg mx-2 text-token-text-secondary"><path fill-rule="evenodd" clip-rule="evenodd" d="M3 8C3 7.44772 3.44772 7 4 7H20C20.5523 7 21 7.44772 21 8C21 8.55228 20.5523 9 20 9H4C3.44772 9 3 8.55228 3 8ZM3 16C3 15.4477 3.44772 15 4 15H14C14.5523 15 15 15.4477 15 16C15 16.5523 14.5523 17 14 17H4C3.44772 17 3 16.5523 3 16Z" fill="#673AB7"></path></svg>
              </v-btn>

              <span
                v-for="(rota, index) in rotas"
                @click="$router.push(rota.path)"
                :key="rota.path"
                :style="
                  `font-size: ${
                    rotas.length > 1 && index === 0 ? '12pt' : '12pt'
                  }; cursor: pointer; color: ${
                    $route.path == '/gestordepedidos' ? 'white' : $theme.primary
                  }`
                "
                class="fonte-bold not-selectable text-no-wrap text-lowercase fonteMini"
              >
                {{ rota.name }}
                <v-icon
                  class="pl-0 pr-1"
                  :color="
                    $route.path == '/gestordepedidos' ? 'white' : $theme.primary
                  "
                  v-if="index < rotas.length - 1"
                  size="13"
                >
                  mdi-chevron-right
                </v-icon>
              </span>
              <v-spacer></v-spacer>
              <slot name="toolbar-search"></slot>
              <v-spacer></v-spacer>
              <span class="fonte text-capitalize grey--text fonteMini">
                {{ $moment().format("LLLL") }}
                <v-btn
                  rounded
                  color="green accent-3"
                  class="fonte ml-2 elevation-0"
                  @click="$refs.onboarding.open('dashboard')"
                  x-small
                  v-if="false"
                >
                  <span class="fonteMini">guia</span>
                  <v-icon class="ml-1" size="16"
                    >mdi-help-circle-outline</v-icon
                  >
                </v-btn>
              </span>
              <v-spacer></v-spacer>
              <div id="options-container">
                <v-btn
                  small
                  v-for="item in options"
                  :color="$theme.primary"
                  dark
                  class="elevation-0 animated flipInX delay-1s mr-1"
                  @click="item.action()"
                  :key="item.nome"
                >
                  <v-icon size="13" class="mr-1" color="#f2f2f2">
                    {{ item.icon }}
                  </v-icon>
                  <span
                    :style="`font-size: 9pt; color: #f2f2f2;`"
                    class="fonte fonteMini text-capitalize"
                    >{{ item.nome }}</span
                  >
                </v-btn>
              </div>
              <div id="notifications-container">
                <v-menu offset-y left bottom :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      class="animate__animated animate__swing  elevation-0"
                      :color="
                        notificationsUnreadeds.length ? $theme.primary : 'grey'
                      "
                      v-on="on"
                    >
                      <span class="font-weight-bold">
                        {{ notificationsUnreadeds.length }}
                      </span>
                      <v-icon size="21" color="#f2f2f2"
                        >mdi-bell-outline</v-icon
                      >
                    </v-btn>
                  </template>
                  <v-card class="pa-3">
                    <v-list-item class="pa-0">
                      <v-avatar class="mr-3 elevation-1" color="#f2f2f2">
                        <v-icon :color="$theme.primary">
                          mdi-bell-outline
                        </v-icon>
                      </v-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          Notificações
                        </v-list-item-title>
                        <span class="fonte fonteMini grey--text">
                          {{ notificationsUnreadeds.length }} Notificações
                          novas</span
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <div class="column centraliza">
                          <v-switch
                            small
                            hide-details
                            class="fonte fonteMini"
                            :color="$theme.primary"
                            v-model="onlyUnreadeds"
                          >
                          </v-switch>
                          <span
                            style="cursor: pointer;"
                            @click="onlyUnreadeds = !onlyUnreadeds"
                            class="fonteMini fonte grey--text"
                          >
                            não lidas
                          </span>
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list
                      class="fonte"
                      v-if="notifications.length > 0"
                      width="350"
                      style="max-height: 300px; overflow: auto;"
                    >
                      <template
                        v-for="(notification, i) in onlyUnreadeds
                          ? notificationsUnreadeds
                          : notifications"
                      >
                        <v-list-item
                          class="item-list-notification"
                          :key="notification._id"
                          v-if="onlyUnreadeds ? !notification.readed : true"
                          @click="abre_modal_view_notification(notification)"
                        >
                          <v-avatar size="31" class="mr-3" color="#f2f2f2">
                            <v-icon :color="$theme.primary" size="21">
                              {{
                                getTypeLinkByTypeNotification(notification).icon
                              }}
                            </v-icon>
                          </v-avatar>
                          <v-list-item-content>
                            <v-list-item-title>
                              <span
                                class=""
                                :class="{
                                  'font-weight-bold': !notification.readed
                                }"
                              >
                                {{
                                  getTypeLinkByTypeNotification(notification)
                                    .title
                                }}
                              </span>
                            </v-list-item-title>
                            <span
                              class="fonte grey--text fonteMini mt-1"
                              :class="{
                                'font-weight-bold': !notification.readed
                              }"
                            >
                              {{ notification.description }}
                            </span>
                            <div class="expande-horizontal">
                              <span
                                class="fonte mt-1"
                                :style="`color: ${$theme.secondary}`"
                                :class="{
                                  'font-weight-bold': !notification.readed
                                }"
                                style="font-size: 6pt;"
                              >
                                {{
                                  $moment(notification.created_at).fromNow()
                                }}</span
                              >
                              <span
                                class="fonte mt-1"
                                :style="`color: ${$theme.secondary}`"
                                v-if="notification.readed"
                                :class="{
                                  'font-weight-bold': !notification.readed
                                }"
                                style="font-size: 6pt;"
                              >
                                , foi lida
                                {{
                                  $moment(notification.readDate).format(
                                    "DD/MM/YY [às] HH:mm"
                                  )
                                }}
                              </span>
                              <span
                                class="fonte fonteMini mt-1"
                                :style="`color: ${$theme.secondary}`"
                                v-else
                                :class="{
                                  'font-weight-bold': !notification.readed
                                }"
                                style="font-size: 6pt;"
                              >
                                , não lida ainda.
                              </span>
                              <v-icon
                                class="ml-2"
                                color="green"
                                size="15"
                                v-if="!notification.readed"
                                >mdi-check</v-icon
                              >
                              <v-icon
                                class="ml-2"
                                color="green"
                                size="15"
                                v-if="notification.readed"
                                >mdi-check-all</v-icon
                              >
                            </div>
                          </v-list-item-content>
                          <!-- <v-list-item-action>
                            
                          </v-list-item-action> -->
                        </v-list-item>
                        <!-- <v-divider :key="`divider-${i}`"></v-divider> -->
                      </template>
                    </v-list>
                    <div
                      v-if="notifications.length === 0"
                      class="notifications-empty-container fonte expande-horizontal centraliza column"
                    >
                      <v-avatar class="mb-3" :color="$theme.primary">
                        <v-icon color="#f2f2f2">mdi-bell-outline</v-icon>
                      </v-avatar>
                      <span :style="`color: ${$theme.primary}`" class="fonte"
                        >Nada por aqui</span
                      >
                      <span class="text-center grey--text fonteMini fonte">
                        Novas notificações aparecerão aqui
                      </span>
                    </div>
                  </v-card>
                </v-menu>
              </div>

              <v-menu offset-y left bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    class="animate__animated ml-1 elevation-0"
                    :color="$theme.secondary"
                    v-on="on"
                  >
                    <span
                      class="fonte-bold"
                      :style="`padding-top: 3px; font-size: 12pt; color: #f2f2f2;`"
                    >
                      {{ getLoggedUser.tenant[0].nome.split(" ")[0] }}
                    </span>
                    <v-icon size="19" color="#f2f2f2" class="ml-2"
                      >mdi-storefront</v-icon
                    >
                  </v-btn>
                </template>

                <v-list nav dense>
                  <v-list-item to="/logged-user">
                    <v-list-item-title>{{
                      getLoggedUser.nome
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="logout">
                    <v-list-item-title>sair</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-menu offset-y v-show="options" left bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-show="options && $vuetify.breakpoint.smAndDown"
                    icon
                    :color="$theme.secondary"
                    v-on="on"
                  >
                    <v-icon :color="$theme.secondary">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list class="fonte">
                  <v-list-item
                    v-for="item in options"
                    :key="item.nome"
                    @click="() => item.action()"
                  >
                    <v-list-item-title class="fonte">{{
                      item.nome
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-app-bar>
          </div>
        </v-flex>
      </div>

      <v-navigation-drawer
        :mini-variant="!drawer"
        :expand-on-hover="$vuetify.breakpoint.lgAndUp && !drawer ? true : false"
        v-if="$vuetify.breakpoint.lgAndUp"
        app
        :light="!dark"
        :dark="dark"
        :color="dark ? '#222' : '#fff'"
        class="drawer-roller-style"
        :class="{
          'vitrify-content': $route.path === '/gestordepedidos',
          'drawer-color': $route.path !== '/gestordepedidos'
        }"
        width="210"
        :v-model="drawer"
      >
        <v-list
          color="transparent"
          style="height: 74vh;"
          dense
          nav
          :light="!dark"
          :dark="dark"
        >
          <v-list-item
            style="min-height: 50px; margin-left: 0px; padding-left: 0px;"
            :class="{
              'white--text': dark || $route.path === '/gestordepedidos',
              'black--text': !dark && $route.path !== '/gestordepedidos'
            }"
            class="fonte"
            to="/logged-user"
          >
            <v-avatar size="36" rounded class="ml-1" v-if="getLoggedUser.profile_image">
              <v-img :src="getLoggedUser.profile_image"></v-img>
            </v-avatar>
            <v-avatar rounded size="40" v-else color="#333">
              <v-icon
                size="26"
                :color="
                  $theme.light && $route.path !== '/gestordepedidos'
                    ? '#f2f2f2'
                    : 'white'
                "
              >
                mdi-account-circle-outline
              </v-icon>
            </v-avatar>
            <v-list-item-content class="ml-2">
              <v-list-item-title v-if="getLoggedUser.nome" class="fonte">
                {{ getLoggedUser.nome.split(" ")[0] }}
              </v-list-item-title>
              <span class="fonteMini grey--text">
                {{ getLoggedUser.permissao.nome }}
              </span>
            </v-list-item-content>
          </v-list-item>

          <template v-for="(menu, index) in menus">
            <v-divider
              v-if="menu.name === 'divider' && menu.permission()"
              :key="`${menu.name}--${index}`"
            ></v-divider>
            <v-list-item
              v-if="menu.name !== 'divider' && menu.permission()"
              :to="menu.route"
              :key="menu.name"
              class="mb-2"
              :class="{
                'white--text':
                  dark ||
                  menu.route === $route.path ||
                  $route.path === '/gestordepedidos',
                'black--text':
                  !dark &&
                  menu.route !== $route.path &&
                  $route.path !== '/gestordepedidos'
              }"
              :active-class="menu.route === $route.path ? 'menuAtivoHome' : ''"
            >
              <v-icon
                size="23"
                class="ml-0 mr-3"
                :color="
                  $theme.light &&
                  ($route.path === menu.route ||
                    $route.path === '/gestordepedidos')
                    ? 'white'
                    : $theme.primary
                "
              >
                {{ menu.icon }}
              </v-icon>
              <v-list-item-content>
                <v-list-item-title class="fonte">
                  <span class="fonte-bold" style="font-weight: 200 !important; font-size: 13pt;">
                    {{ menu.name }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item
            :class="{
              'grey--text': $route.path === '/gestordepedidos' ? true : false
            }"
            class="fonte grey--text fonteMini"
            v-if="false"
            to="/escolher-empresa"
          >
            <v-icon size="18" class="mr-1" color="orange">
              mdi-swap-horizontal
            </v-icon>
            <v-list-item-title>
              Trocar de empresa
            </v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item
            :class="{
              'grey--text': $route.path === '/gestordepedidos' ? true : false
            }"
            class="fonte pl-3 grey--text fonteMini"
            @click="logout"
          >
            <v-icon size="16" class="mr-2" color="red">
              mdi-exit-run
            </v-icon>
            <v-list-item-title>sair</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-navigation-drawer
        v-else
        app
        v-model="drawer"
        class="drawer-roller-style"
        :light="$theme.light"
        :dark="$theme.dark"
        :class="{
          'vitrify-content': $route.path === '/gestordepedidos',
          'drawer-color': $route.path !== '/gestordepedidos'
        }"
      >
        <v-list
          color="transparent"
          style="height: 74vh;"
          dense
          nav
          :light="$theme.light"
          :dark="$theme.dark"
        >
          <v-list-item
            style="min-height: 30px; margin-left: 0px; padding-left: 0px;"
            :class="{
              'white--text': $theme.dark || $route.path === '/gestordepedidos',
              'black--text': $theme.light && $route.path !== '/gestordepedidos'
            }"
            class="fonte"
            to="/logged-user"
          >
            <v-avatar size="27" class="mr-2" v-if="getLoggedUser.profile_image">
              <v-img :src="getLoggedUser.profile_image"></v-img>
            </v-avatar>
            <v-avatar rounded size="40" v-else :color="$theme.secondary">
              <v-icon
                size="26"
                :color="
                  $theme.light && $route.path !== '/gestordepedidos'
                    ? '#333'
                    : 'white'
                "
              >
                mdi-account-circle-outline
              </v-icon>
            </v-avatar>
            <v-list-item-content class="ml-2">
              <v-list-item-title v-if="getLoggedUser.nome" class="fonte">
                {{ getLoggedUser.nome.split(" ")[0] }}
              </v-list-item-title>
              <span class="fonteMini grey--text">
                {{ getLoggedUser.permissao.nome }}
              </span>
            </v-list-item-content>
          </v-list-item>

          <template v-for="(menu, index) in menus">
            <v-divider
              v-if="menu.name === 'divider' && menu.permission()"
              :key="`${menu.name}--${index}`"
            ></v-divider>
            <v-list-item
              v-if="menu.name !== 'divider' && menu.permission()"
              :to="menu.route"
              :key="menu.name"
              :class="{
                'white--text':
                  $theme.dark ||
                  menu.route === $route.path ||
                  $route.path === '/gestordepedidos',
                'black--text':
                  $theme.light &&
                  menu.route !== $route.path &&
                  $route.path !== '/gestordepedidos'
              }"
              :active-class="menu.route === $route.path ? 'menuAtivoHome' : ''"
            >
              <v-icon
                size="19"
                class="ml-1 mr-1"
                :color="
                  $theme.light &&
                  ($route.path === menu.route ||
                    $route.path === '/gestordepedidos')
                    ? 'white'
                    : '#555'
                "
              >
                {{ menu.icon }}
              </v-icon>
              <v-list-item-content>
                <v-list-item-title class="fonte">
                  <span class="fonte" style="font-size: 9pt;">
                    {{ menu.name }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item
            :class="{
              'grey--text': $route.path === '/gestordepedidos' ? true : false
            }"
            class="fonte grey--text fonteMini"
            v-if="false"
            to="/escolher-empresa"
          >
            <v-icon size="18" class="mr-1" color="orange">
              mdi-swap-horizontal
            </v-icon>
            <v-list-item-title>
              Trocar de empresa
            </v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item
            :class="{
              'grey--text': $route.path === '/gestordepedidos' ? true : false
            }"
            class="fonte pl-3 grey--text fonteMini"
            @click="logout"
          >
            <v-icon size="16" class="mr-2" color="red">
              mdi-exit-run
            </v-icon>
            <v-list-item-title>sair</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main
        class="molde"
        :class="{
          'blacked-background': $route.path == '/gestordepedidos'
        }"
        :style="
          `border-radius: 20px !important; ${
            $route.path == '/gestordepedidos' ? '' : $theme.background
          }`
        "
      >
        <div
          class="expande-horizontal centraliza"
          :style="
            `max-height: ${
              toolbar_off ? '100vh' : '97vh'
            }; min-height: 97vh; border-radius: 6px;`
          "
        >
          <v-flex xs12 md12>
            <div style="border-radius: 6px;" class="expande-horizontal wrap">
              <slot></slot>
            </div>
          </v-flex>
        </div>
      </v-main>
    </v-layout>
    <ModalOnboarding ref="onboarding" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalOnboarding from "./ModalOnboarding";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { onBackgroundMessage } from "firebase/messaging/sw";
export default {
  props: [
    "title",
    "options",
    "icon",
    "action",
    "origem",
    "rotas",
    "toolbar_off"
  ],
  data() {
    return {
      dark: false,
      drawer: false,
      onlyUnreadeds: false,
      transparency_routes: ["/dashboard", "/atendimento", "/gestordepedidos"],
      menus: [
        {
          icon: "mdi-storefront-outline",
          name: "Dashboard",
          route: "/dashboard",
          permission: () =>
            this.get_permission_user["dashboard"] &&
            this.get_permission_plan.includes("dashboard")
        },
        {
          icon: "mdi-cash-register",
          name: "Vender",
          route: "/atendimento",
          permission: () =>
            this.get_permission_user["vender"] &&
            this.get_permission_plan.includes("vender")
        },
        {
          icon: "mdi-arrow-decision",
          name: "Vendas",
          route: "/gestordepedidos",
          permission: () =>
            this.get_permission_user["gestor-de-pedidos"] &&
            this.get_permission_plan.includes("gestor-de-pedidos")
        },
        {
          name: "divider",
          permission: () => false
        },
        {
          icon: "mdi-calendar-badge-outline",
          name: "Minha Agenda",
          route: "/minha-agenda",
          permission: () =>
            this.get_permission_user["minha-agenda"] &&
            this.get_permission_plan.includes("minha-agenda")
        },
        {
          icon: "mdi-calendar-edit-outline",
          name: "Agenda",
          route: "/agenda",
          permission: () =>
            this.get_permission_user["agenda"] &&
            this.get_permission_plan.includes("agenda")
        },
        {
          name: "divider",
          permission: () => false
        },
        {
          icon: "mdi-clipboard-list-outline",
          name: "Lista de compras",
          route: "/lista-de-compras",
          permission: () =>
            this.get_permission_user["lista-de-compras"] &&
            this.get_permission_plan.includes("lista-de-compra")
        },
        {
          name: "divider",
          permission: () => false
        },
        {
          icon: "mdi-library-outline",
          name: "Produtos",
          route: "/menuonline",
          permission: () =>
            this.get_permission_user["produtos"] &&
            this.get_permission_plan.includes("produtos")
        },
        {
          icon: "mdi-library-outline",
          name: "Insumos",
          route: "/insumos",
          permission: () =>
            this.get_permission_user["insumos"] &&
            this.get_permission_plan.includes("insumos")
        },
        {
          icon: "mdi-account-circle-outline",
          name: "Clientes",
          route: "/clientes",
          permission: () =>
            this.get_permission_user["clientes"] &&
            this.get_permission_plan.includes("clientes")
        },
        {
          icon: "mdi-account-group-outline",
          name: "Colaboradores",
          route: "/funcionarios",
          permission: () =>
            this.get_permission_user["colaboradores"] &&
            this.get_permission_plan.includes("colaboradores")
        },
        {
          name: "divider",
          permission: () => false
        },
        {
          icon: "mdi-ballot-outline",
          name: "Contas",
          route: "/invoices",
          permission: () =>
            this.get_permission_user["contas"] &&
            this.get_permission_plan.includes("contas")
        },
        // {
        //   icon: "mdi-receipt",
        //   name: "Relatório de contas",
        //   route: "/caixas",
        //   permission: () => this.get_permission_user["relatorios"]
        // },
        {
          name: "divider",
          permission: () => false
        },
        {
          icon: "mdi-receipt-outline",
          name: "Áreas de Entrega",
          route: "/delivery",
          permission: () => this.get_permission_user["configurar-delivery"]
        },
        {
          icon: "mdi-receipt-outline",
          name: "Histórico de vendas",
          route: "/transacoes",
          permission: () => this.get_permission_user["acompanhamento-de-caixa"]
        },
        {
          icon: "mdi-receipt-text-check-outline",
          name: "Contabilidade Caixas",
          route: "/acompanhamento-de-caixa",
          permission: () =>
            this.get_permission_user["contabilidade-de-caixas"] &&
            this.get_permission_plan.includes("contabilidade-de-caixas")
        },
        {
          icon: "mdi-receipt-text-check-outline",
          name: "Relatório",
          route: "/caixas",
          permission: () =>
            this.get_permission_user["relatorio-de-contabilidade"] &&
            this.get_permission_plan.includes("relatorio-de-contabilidade")
        },
        {
          name: "divider",
          permission: () => false
        },
        {
          icon: "mdi-lock-outline",
          name: "Permissões",
          route: "/permissoes",
          permission: () =>
            this.get_permission_user["permissoes"] &&
            this.get_permission_plan.includes("permissoes")
        },
        // {
        //   icon: "mdi-storefront-outline",
        //   name: "Gerenciar Empresas",
        //   route: "/marketplaces",
        //   permission: () => this.getLoggedUser.admin
        // },
        {
          icon: "mdi-cog-outline",
          name: "Configurações",
          route: "/configurar-loja",
          permission: () =>
            this.get_permission_user["configurar-loja"] &&
            this.getLoggedUser.admin &&
            this.get_permission_plan.includes("configurar-loja")
        }
      ],
      notifications: [],
      isAndroid: false,
      miniVariant: true
    };
  },
  watch: {
    drawer(val) {
      localStorage.setItem("drawer", val);
    }
  },
  components: {
    ModalOnboarding
  },
  computed: {
    ...mapGetters([
      "getLoggedUser",
      "get_permission_user",
      "get_permission_plan"
    ]),
    notificationsUnreadeds() {
      return this.notifications.filter(n => !n.readed);
    }
  },
  methods: {
    ...mapActions(["logout"]),
    getLinkNotify(notification) {
      const types = {
        invoice_payment: {
          title: "Pagamento de conta",
          link: `/invoice?t_id=${notification.payload._id}`
        },
        new_comment: {
          title: "Novo comentário",
          link: `/gestordepedidos?t_id=${notification.payload._id}`
        },
        employee_balance_added: {
          title: "Salário adicionado manualmente",
          link: `/funcionarios?f_id=${
            notification.client ? notification.client._id : ""
          }&type=salario&e_id=${notification.payload._id}`
        },
        employee_balance_withdraw: {
          title: "Vale emitido",
          link: `/funcionarios?f_id=${notification.payload._id}&type=vale&extra=${notification.payload._id}`
        },
        sale_updated: {
          title: "Venda atualizada",
          link: `/gestordepedidos?t_id=${notification.payload._id}`
        },
        stock_up: {
          title: "Estoque aumentado",
          link: `/insumos?p_id=${notification.payload._id}&type=estoque`
        },
        stock_down: {
          title: "Estoque diminuido",
          link: `/insumos?p_id=${notification.payload._id}&type=estoque`
        },
        stock_low: {
          title: "Estoque baixo",
          link: `/insumos?p_id=${notification.payload._id}&type=estoque`
        },
        box_closed: {
          title: "Caixa fechado",
          link: `/acompanhamento-de-caixa?c_id=${notification.payload._id}`
        },
        box_opened: {
          title: "Caixa aberto",
          link: `/acompanhamento-de-caixa?c_id=${notification.payload._id}`
        },
        new_payment: {
          title: "Pagamento Recebido",
          link: `/gestordepedidos?t_id=${notification.payload._id}`
        },
        new_comment_external_movimentation: {
          title: "Novo comentário",
          link: `/agenda?t_id=${notification.payload._id}`
        },
        new_external_movimentation: {
          title: "Novo agendamento",
          link: `/agenda?e_id=${notification.payload._id}`
        },
        new_external_movimentation_viewed: {
          title: "O agendamento foi visualizado",
          link: `/agenda?e_id=${notification.payload._id}`
        },
        external_movimentation_updated: {
          title: "Um agendamento foi atualizado",
          link: `/minha-agenda?e_id=${notification.payload._id}`
        },
        transaction_unsigned: {
          title: "Registro da conferência de caixa foi Cancelado",
          link: `/acompanhamento-de-caixa?c_id=${notification.payload._id}`
        },
        transaction_signed: {
          title: "Registro da conferência de caixa foi Aprovado",
          link: `/acompanhamento-de-caixa?c_id=${notification.payload._id}`
        },
        seller_signup: {
          title: "Um vendedor se cadastrou",
          link: `https://erp.stonepro.com.br/#/sellers?c_id=${notification.payload._id}`
        },
        "signature-flow-initiated": {
          title: "Um novo cliente se cadastrou",
          link: `https://erp.stonepro.com.br/#/funcionarios?c_id=${notification.payload._id}`
        },
        "signature-flow-progress": {
          title: "Um cliente criou sua loja",
          link: `https://erp.stonepro.com.br/#/funcionarios?c_id=${notification.payload._id}`
        }
      };
      return types[notification.type];
    },
    abre_modal_view_notification(notify) {
      if (!notify.readed) {
        this.markNotificationAsReaded(notify);
      }
      const route = this.getLinkNotify(notify);
      this.$router.push(route.link);
    },
    getIconNotification(type) {
      const types = {
        new_comment: "mdi-comment-text-outline",
        new_sale: "mdi-cash-register",
        sale_updated: "mdi-satellite-variant",
        stock_low: "mdi-trending-down",
        stock_down: "mdi-basket-minus-outline",
        stock_up: "mdi-basket-plus-outline"
      };
      return types[type];
    },
    getTypeLinkByTypeNotification(notification) {
      const types = {
        invoice_payment: {
          title: "Lançamento de caixa",
          icon: "mdi-receipt-text-check-outline"
        },
        new_comment: {
          title: "Novo comentário",
          icon: "mdi-comment-text-outline"
        },
        employee_balance_added: {
          title: "Salário adicionado manualmente",
          icon: "mdi-account-plus-outline"
        },
        employee_balance_withdraw: {
          title: "Vale emitido",
          icon: "mdi-account-minus-outline"
        },
        sale_updated: {
          title: "Venda atualizada",
          icon: "mdi-cash-register"
        },
        stock_up: {
          title: "Estoque aumentado",
          icon: "mdi-cart-arrow-up"
        },
        stock_down: {
          title: "Estoque diminuido",
          icon: "mdi-cart-arrow-down"
        },
        stock_low: {
          title: "Estoque baixo",
          icon: "mdi-trending-down"
        },
        box_closed: {
          title: "Caixa fechado",
          icon: "mdi-cash-register"
        },
        box_opened: {
          title: "Caixa aberto",
          icon: "mdi-cash-register"
        },
        new_payment: {
          title: "Pagamento",
          icon: "mdi-cash-register"
        },
        new_comment_external_movimentation: {
          title: "Novo comentário",
          icon: "mdi-comment-text-outline"
        },
        new_external_movimentation: {
          title: "Novo agendamento",
          icon: "mdi-calendar-clock"
        },
        new_external_movimentation_viewed: {
          title: "O agendamento foi visualizado",
          icon: "mdi-calendar-clock"
        },
        external_movimentation_updated: {
          title: "Um agendamento foi atualizado",
          icon: "mdi-calendar-clock"
        },
        transaction_unsigned: {
          title: "Registro da conferência de caixa foi Cancelado",
          icon: "mdi-calendar-clock"
        },
        transaction_signed: {
          title: "Registro da conferência de caixa foi Aprovado",
          icon: "mdi-success"
        },
        seller_signup: {
          title: "Um vendedor se cadastrou",
          icon: "mdi-account-plus-outline"
        },
        "signature-flow-initiated": {
          title: "Um novo cliente se cadastrou",
          icon: "mdi-account-plus-outline"
        },
        "signature-flow-progress": {
          title: "Um cliente criou sua loja",
          icon: "mdi-account-plus-outline"
        }
      };
      if (!types[notification.type])
        return { title: notification.type, icon: "help_outline" };
      return types[notification.type];
    },
    getNotificationDescription(type) {
      const types = {
        new_comment: "Novo comentário",
        new_sale: "Nova venda",
        sale_updated: "Venda atualizada",
        stock_low: "Estoque baixo",
        stock_up: "Entrada de estoque",
        stock_down: "Saída de estoque"
      };
      return types[type];
    },
    listNotifications() {
      this.$run("notifications/list-my-notifications").then(res => {
        this.notifications = res.data;
      });
    },
    markNotificationAsReaded(notification) {
      this.$run("notifications/mark-as-readed", { _id: notification._id }).then(
        res => {
          this.listNotifications();
        }
      );
    },
    goBack() {
      if (this.action !== undefined) {
        this.action();
      } else {
        this.$router.go(-1);
      }
    },
    notification_sound() {
      const audio = new Audio("call_in.mp3");
      audio.play();
    }
  },
  created() {
    this.drawer = !this.isAndroid;
    // this.listNotifications();
    this.isAndroid =
      navigator.userAgent.match(/Android/i) ||
      this.$vuetify.breakpoint.smAndDown;

    // let cordova = window.cordova || false;
    // const self = this;
    // if (cordova) {
    //   cordova.plugins.firebase.messaging
    //     .requestPermission({ forceShow: false })
    //     .then(function() {
    //       console.log("Push messaging is allowed");
    //       cordova.plugins.firebase.messaging
    //         .getToken()
    //         .then(function(token) {
    //           self.$db.ref(`pn/${self.getLoggedUser._id}`).set({
    //             token: token,
    //             user: self.getLoggedUser._id
    //           });
    //         })
    //         .catch(function(err) {
    //           alert(err);
    //         });
    //     });
    //   StatusBar.backgroundColorByHexString(this.$theme.secondary);
    // }
    if (this.$vuetify.breakpoint.smAndDown) {
      this.drawer = false;
    } else {
      this.drawer = localStorage.getItem("drawer") === "true";
    }
  },
  mounted() {
    // if (localStorage.dark === "true") {
    //   this.dark = true;
    // }
    // if (!sessionStorage.nt && this.$route.path !== '/dashboard') {
    //   if (Notification.permission === "granted") {
    //     sessionStorage.nt = "ok";
    //     const messaging = getMessaging();
    //     getToken(messaging, {
    //       vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY
    //     }).then(currentToken  => {
    //       if (currentToken) {
    //         this.$run("notifications/register-token", {
    //           notification_token: currentToken
    //         }).catch(res => {
    //           console.log("erro no token", res);
    //         });
    //       } else {
    //         alert(
    //           "você recusou o envio de notificações, para receber nossos fluxos de notificação, acesse as configurações do seu navegador e permita o envio de notificações."
    //         );
    //       }
    //       onMessage(messaging, payload => {
    //         const { data } = payload;
    //         this.listNotifications();
    //         this.notification_sound();
    //       });
    //     });
    //   } else {
    //     Notification.requestPermission()
    //       .then(async permission => {
    //         if (permission === "granted") {
    //           const messaging = getMessaging();
    //           getToken(messaging, {
    //             vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY
    //           }).then(currentToken => {
    //             if (currentToken) {
    //               this.$run("notifications/register-token", {
    //                 notification_token: currentToken
    //               })
    //                 .then(() => {
    //                   sessionStorage.nt = "ok";
    //                 })
    //                 .catch(res => {
    //                   console.log("erro no token", res);
    //                 });
    //             } else {
    //               alert(
    //                 "você recusou o envio de notificações, para receber nossos fluxos de notificação, acesse as configurações do seu navegador e permita o envio de notificações."
    //               );
    //             }
    //             onMessage(messaging, payload => {
    //               this.listNotifications();
    //               this.notification_sound();
    //             });
    //           });
    //         }
    //       })
    //       .catch(err => {
    //         console.log("wtf", err);
    //       });
    //   }
    // }
  }
};
</script>

<style>
.drawer-color {
  background: #EDE7F6;
}
.centraliza-tudo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.molde {
  display: flex;
  width: 100%;
  border-radius: 6px;
}

.v-navigation-drawer__content::-webkit-scrollbar {
  width: 1px; /* width of the entire scrollbar */
}

.v-navigation-drawer__content::-webkit-scrollbar-track {
  background: rgb(122, 122, 122); /* color of the tracking area */
  border-radius: 6px;
}

.v-navigation-drawer__content::-webkit-scrollbar-thumb {
  background-color: #EDE7F6; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
.notifications-empty-container {
  display: flex;
  width: 300px;
  border-radius: 6px;
  min-height: 300px;
  background: #fff;
}
.item-list-notification {
  border-radius: 6px;
  border: 1px solid #f2f2f2;
  margin-bottom: 10px;
  padding-right: 15px;
  padding: 6px;
}
.v-navigation-drawer__border {
    display: none !important;
  }
  .blacked-background {
    background: rgba(0, 0, 0, 0.2);
  }
</style>
